import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import ProductHeader from "gatsby-theme-rothenberger/src/components/blocks/ProductHeader"

const DemoProduct = () => {
  const breadcrumbs = [
    { link: "/", link_text: "Produkte" },
    { link: "/", link_text: "Installation" },
    { link: "/", link_text: "Pressen" },
  ]
  const body = [
    {
      component: "product_tabs_block",
      tab_items: [
        {
          title: "Produktinformationen",
          content: [
            {
              component: "product_overview_block",
              text: "Mit der elektrohydraulischen, radialen Pressmaschine ROMAX COMPACT TT können SHK-Installateure Hausinstallationen, Etagen- und Servicearbeiten sowie Arbeiten in Zwangslagen meistern. Mit Turbo-Druck verpresst die ROMAX COMPACT TT kraftvoll Metallfittings bis Ø 35 mm - und das in nur 3 Sekunden. Durch ihre Bauform in zwei Zylindern findet ihre geballte Power Platz auf nur 34 cm Pressmaschine. Das perfekt ausbalancierte Gewicht erleichtert Arbeiten über längere Strecken.",
              infos: [
                {
                  title: "Merkmale",
                  content: [
                    {
                      text: "Schnell: durch Turbo-Druck dauert eine Verpressung nur 3 Sekunden",
                    },
                    { text: "Stark: verpresst Metallfittings bis Ø 35 mm" },
                    {
                      text: "Handlich: perfekt ausbalanciert - der Schwerpunkt liegt da, wo der Nutzer sie greift",
                    },
                    {
                      text: "Klein und leicht: mit 34 cm Länge wiegt die ROMAX Compact TT nur 2,5 kg",
                    },
                  ],
                },
                { title: "Funktionen" },
                { title: "Zubehör" },
                {
                  title: "Dokumente",
                  content: [
                    { link: "/fake-pdf-link.pdf", text: "Wichtiges Dokument" },
                  ],
                },
              ],
              product_images: [
                { image: "https://picsum.photos/500/500" },
                { image: "https://picsum.photos/501/501" },
                { image: "https://picsum.photos/500/500" },
                { image: "https://picsum.photos/501/501" },
              ],
            },
          ],
        },
        {
          title: "Technische Daten",
          content: [
            { component: "product_header_block", headline: "Technische Daten" },
            {
              component: "divider_block",
            },
            {
              component: "product_data_block",
            },
          ],
        },
        {
          title: "Bewertungen (X)",
          content: [
            { component: "product_header_block", headline: "Bewertungen" },
            {
              component: "divider_block",
            },
            {
              component: "product_rating_overview_block",
            },
            {
              component: "product_rating_block",
              author: "Barbara Palson",
              country: "Deutschland",
              date: "28.10.2021",
              rating: 4,
              headline:
                "Für den Alltag besser als meine kabelgebundene Pressmaschine",
              text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.\n\n In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.\n\n Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet.",
            },
          ],
        },
      ],
    },
    {
      component: "divider_block",
    },
    {
      component: "product_feature_block",
      image: "https://picsum.photos/800/600",
      headline: "Leicht und handlich",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit.",
      text_position: "left",
    },
    {
      component: "product_feature_block",
      image: "https://picsum.photos/800/600",
      headline: "Leicht und handlich",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit.",
    },
    {
      component: "divider_block",
    },
    {
      component: "variant_cards_block",
      headline: "Wähle eine Variante",
      variant_items: [
        {
          active: true,
          headline: "Basisset 1",
          text: "No. 1000002115",
          image: "https://picsum.photos/500/500",
          accessories: {
            text: "Zubehör 1, Zubehör 2, Teil A, Teil B, Teil C, Ladegerät",
          },
        },
        {
          headline: "Basisset 2",
          text: "No. 1000002116",
          image: "https://picsum.photos/501/501",
          accessories: {
            text: "Zubehör 1, Zubehör 2, Teil A, Teil B, Teil C, Ladegerät",
          },
        },
        {
          headline: "Basisset 3",
          text: "No. 1000002117",
          image: "https://picsum.photos/500/500",
          accessories: {
            text: "Zubehör 1, Zubehör 2, Teil A, Teil B, Teil C, Ladegerät",
          },
        },
        {
          headline: "Basisset 4",
          text: "No. 1000002118",
          image: "https://picsum.photos/501/501",
          accessories: {
            text: "Zubehör 1, Zubehör 2, Teil A, Teil B, Teil C, Ladegerät",
          },
        },
        {
          headline: "Basisset 5",
          text: "No. 1000002119",
          image: "https://picsum.photos/500/500",
          accessories: {
            text: "Zubehör 1, Zubehör 2, Teil A, Teil B, Teil C, Ladegerät",
          },
        },
      ],
    },
  ]
  return (
    <Layout>
      <ProductHeader
        block={{ headline: "ROMAX COMPACT TT" }}
        breadcrumbs={breadcrumbs}
      />
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default DemoProduct
