import React, { useState, useRef, useEffect } from "react"
import SbEditable from "storyblok-react"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import { Link } from "gatsby"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const ProductHeader = ({ block, breadcrumbs }) => {
  if (block.image?.filename?.length > 0) {
    return (
      <SbEditable content={block}>
        <section className="relative lg:mb-16 bg-gray-800 md:h-80">
          <div className="h-full mx-auto text-white max-w-screen-2xl px-grid">
            <div className="flex flex-row flex-wrap h-full -mx-grid">
              <div className="w-full md:order-last md:w-1/2 lg:w-2/5 md:px-4 md:h-80">
                <div className="relative h-full">
                  <Image
                    image={block.image}
                    className="block object-cover h-full"
                  />

                  <div
                    className="absolute inset-0 hidden md:block"
                    style={{
                      background:
                        "linear-gradient(to right, #21272a, transparent 25%, transparent 60%, #21272a)",
                    }}
                  ></div>
                </div>
              </div>
              <div className="w-full py-8 md:w-1/2 lg:w-3/5 px-grid">
                <div className="block mb-2 -ml-2 text-sm">
                  {breadcrumbs?.map((breadcrumb, index) => (
                    <span key={index}>
                      {index > 0 && (
                        <span className="inline-block h-4 text-gray-600">
                          /
                        </span>
                      )}
                      <Link
                        className={`px-2 hover:underline text-gray-200`}
                        key={index}
                        to={breadcrumb.link}
                      >
                        {breadcrumb.link_text}
                      </Link>
                    </span>
                  ))}
                </div>
                <Headline
                  className="text-white"
                  headline={block.headline}
                  headlineLevel="h2"
                  tagName="h1"
                />
              </div>
            </div>
          </div>
        </section>
      </SbEditable>
    )
  } else {
    return (
      <SbEditable content={block}>
        <section data-var-uid={block._uid}  className="py-8 bg-gray-800">
          <div className="mx-auto text-white max-w-screen-2xl px-grid">
            <div className="block mb-2 -ml-2 text-sm">
              {breadcrumbs?.map((breadcrumb, index) => (
                <span key={index}>
                  {index > 0 && (
                    <span className="inline-block h-4 text-gray-600">/</span>
                  )}
                  <Link
                    className={`px-2 hover:underline text-gray-200`}
                    key={index}
                    to={breadcrumb.link}
                  >
                    {breadcrumb.link_text}
                  </Link>
                </span>
              ))}
            </div>
            <Headline
              className="text-white"
              headline={block.headline}
              headlineLevel="h2"
              tagName="h1"
            />
          </div>
        </section>
      </SbEditable>
    )
  }
}

export default ProductHeader
